import red from '@material-ui/core/colors/red'
import pink from '@material-ui/core/colors/pink'
import green from '@material-ui/core/colors/green'
import indigo from '@material-ui/core/colors/indigo'
import { grey, blueGrey } from '@material-ui/core/colors'

const themes = [
  {
    id: 'default',
  },
  {
    id: 'red',
    color: red[500],
    source: {
      palette: {
        primary: red,
        secondary: pink,
        error: red,
      },
    },
  },
  {
    id: 'green',
    color: green[500],
    source: {
      palette: {
        primary: green,
        secondary: red,
        error: red,
      },
    },
  },
  {
    id: 'pink',
    color: pink[50],
    source: {
      palette: {
        primary: pink,
        secondary: red,
        error: red,
      },
    },
  },
  {
    id: 'grey',
    color: grey[50],
    source: {
      palette: {
        primary: grey,
        secondary: pink,
        error: red,
      },
      overrides: {
        MuiAppBar: {
          colorInherit: {
            backgroundColor: '#689f38',
            color: '#fff',
          },
        },
      },
      props: {
        MuiAppBar: {
          color: 'transparent',
        },
      },

    },
  },
  {
    id: 'blueGrey',
    color: blueGrey[50],
    source: {
      palette: {
        primary: blueGrey,
        secondary: pink,
        error: red,
      },
      overrides: {
        MuiAppBar: {
          colorInherit: {
            backgroundColor: '#689f38',
            color: '#fff',
          },
        },
      },
      props: {
        MuiAppBar: {
          color: 'transparent',
        },
      },

    },
  },
  {
    id: 'articoin',
    color: blueGrey[50],
    source: {
      typography: {
        fontFamily: [
          'Rubik',
          'sans-serif',
        ].join(','),
        h1: {
          fontFamily: ['Abhaya Libre','serif'].join(',')
        },
        h2: {
          fontFamily: ['Abhaya Libre','serif'].join(',')
        },
        h3: {
          fontFamily: ['Abhaya Libre','serif'].join(',')
        },
        h4: {
          fontFamily: ['Abhaya Libre','serif'].join(',')
        },        
      },      
      palette: {
        type: 'light',
        primary: {
          main: '#003974',
        },
        secondary: pink,
        error: red,
        /*
        action: {
          active: blueGrey[200],
          activeOpacity: 1,
          hover: blueGrey[100],
          hoverOpacity: 0.7,
          focus: blueGrey[600],
          focusOpacity: 1,
          selected: blueGrey[600],
          selectedOpacity: 1
        },        
        */
      },
      overrides: {
        MuiAppBar: {
          colorInherit: {
            //backgroundColor: '#689f38',
            color: '#fff',
          },
        },
        MuiDrawer: {
          paperAnchorDockedLeft: {
            boxSizing: "border-box"
          },
        },
        MuiListItemIcon: {
          root: {
            minWidth: "30px",
          }
        },
        MuiCardActions: {
          root: {
            alignItems: 'stretch',
          }
        },
        MuiButton: {
          root: {
            border: "0",
            fontWeight: 500,
            fontSize: 16,
            color: "#FFFFFF",
            backgroundColor: "#003974",
            padding: "0 0px",
            borderRadius: "10px",
            textTransform: "capitalize",
            cursor: "pointer",
            margin: 2,
            boxShadow: "0px 4px 20px rgba(0, 123, 145, 0.15)",
            '@media (hover: none)': {
              '&:hover': {
                color: '#787878',
                backgroundColor: '#336190',
              },
            },
            '&:hover': {
              color: "#FFFFFF",
              backgroundColor: '#336190',
            },
            "&:disabled": {
              opacity: .75,
              backgroundColor: '#A9A9A9',
              color: "#FFFFFF",
            },
          },
          text: {
            padding: 6,
          },
          textPrimary: {
            color: "#FFFFFF",
            '@media (hover: none)': {
              '&:hover': {
                color: '#787878',
                backgroundColor: '#336190',
              },
            },
            '&:hover': {
              color: '#FFFFFF',
              backgroundColor: '#336190',
            },
          },
          label: {
            pointerEvents: 'none'
          }
        },
      },
      props: {
        MuiAppBar: {
          color: 'transparent',
        },
      },
    },
  },
]

export default themes

