/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'
import { Route } from 'react-router-dom'

const SignIn = lazy(() => import('../pages/SignIn/SignIn'))
const SignInResult = lazy(() => import('../pages/SignIn/OAuthSignInResult'))
const FBSignInResult = lazy(() => import('../pages/SignIn/FBOAuthSignInResult'))
const SignUp = lazy(() => import('../pages/SignUp/SignUp'))
const AccountActication = lazy(() => import('../pages/SignUp/Activate'))
const ForgotPasswordRequest = lazy(() => import('../pages/ForgotPassword/ForgotPasswordRequest'))
const PasswordReset = lazy(() => import('../pages/ForgotPassword/PasswordReset'))
const About = lazy(() => import('../pages/About'))
//const Home = lazy(() => import('../pages/Home'))
const Playlists = lazy(() => import('../pages/Playlists'))
const PlaylistDetails = lazy(() => import('../pages/PlaylistDetails'))
const Explore = lazy(() => import('../pages/Explore'))
const RemotePlayList = lazy(() => import('../pages/RemotePlayList'))
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))
const CollectionSlideShow = lazy(() => import('../pages/PlaylistSlideShow'))
const PaymentResult = lazy(() => import('../pages/PaymentResult/Received'))
const PaymentCanceled = lazy(() => import('../pages/PaymentResult/Canceled'))
const AssetDetails = lazy(() => import('../pages/AssetDetails'))
const MyAssets = lazy(() => import('../pages/Wallet/MyAssets'))
const MyServices = lazy(() => import('../pages/Wallet/MyServices'))
const MyProvidedServices = lazy(() => import('../pages/Wallet/MyProvidedServices'))
const MyStyleTransferOrders = lazy(() => import('../pages/Wallet/MyStyleTransferOrders'))

const MyFavoriteAssets = lazy(() => import('../pages/Wallet/MyFavoriteAssets'))
const MyOrders = lazy(() => import('../pages/Wallet/MyOrders'))
const Campaigns = lazy(() => import('../pages/Campaigns'))
const CampaignDetails = lazy(() => import('../pages/CampaignDetails'))
const Artist = lazy(() => import('../pages/Artist'))
const User = lazy(() => import('../pages/User'))

const Terms = lazy(() => import('../pages/Terms'))
const SBTNTerms = lazy(() => import('../pages/Terms/sbtn_terms'))
const SBTNTermsV2 = lazy(() => import('../pages/Terms/sbtn_terms_v2'))
const HASETerms = lazy(() => import('../pages/Terms/hase_terms'))
const PaymeCNY2023Terms = lazy(() => import('../pages/Terms/payme_cny_2023_terms'))

const PrivacyPolicy = lazy(() => import('../pages/Terms/privacy_policy'))
const GreenNFT = lazy(() => import('../pages/GreenNFT'))
const CuratedCreators = lazy(() => import('../pages/CuratedCreators'))
const EventPage = lazy(() => import('../pages/MediaEvents/MediaEvents'))
const AIlab = lazy(() => import('../pages/AIlab'))


//static path for campaign
const CampaignEcoMatcher202204 = lazy(() => import('../pages/CampaignDetails/CampaignEcoMatcher202204'))
const CampaignFHS202201 = lazy(() => import('../pages/CampaignDetails/CampaignFHS202201'))
const CampaignHKPDA202203 = lazy(() => import('../pages/CampaignDetails/CampaignHKPDA202203'))
const CampaignROOMTOREAD202203 = lazy(() => import('../pages/CampaignDetails/CampaignROOMTOREAD202203'))
const Innoverse = lazy(() => import('../pages/CampaignDetails/Innoverse'))
const InnoverseStyleTransfer = lazy(() => import('../pages/CampaignDetails/InnoverseStyleTransfer'))
const InnoverseControlTrxDetails = lazy(() => import('../pages/CampaignDetails/InnoverseControlTrxDetails'))
const InnoverseStyleTransferDetails = lazy(() => import('../pages/CampaignDetails/InnoverseStyleTransferDetails'))
const Arles = lazy(() => import('../pages/CampaignDetails/Arles'))
const ArlesControlTrxDetails = lazy(() => import('../pages/CampaignDetails/ArlesControlTrxDetails'))
const CampaignMinisaur = lazy(() => import('../pages/CampaignDetails/CampaignMinisaur'))
const CampaignHASE202206 = lazy(() => import('../pages/CampaignDetails/CampaignHASE202206'))

//services
const ServiceMarketPlace = lazy(() => import('../pages/Services/ServiceMarketPlace'))
const ServicePackageDetails = lazy(() => import('../pages/Services/ServicePackageDetails'))


const Scanner = lazy(() => import('../pages/Scanner'))


const routes = [
  <UnauthorizedRoute path="/signin" redirectTo="/" exact component={SignIn} />,
  <UnauthorizedRoute path="/signup" redirectTo="/" exact component={SignUp} />,
  <UnauthorizedRoute path="/forgot-password" redirectTo="/" exact component={ForgotPasswordRequest} />,
  <UnauthorizedRoute path="/activate/:uid/:token" redirectTo="/" exact component={AccountActication} />,
  <UnauthorizedRoute path="/accounts/profile" redirectTo="/" exact component={SignInResult} />,
  <UnauthorizedRoute path="/accounts/fbprofile" redirectTo="/" exact component={FBSignInResult} />,
  <UnauthorizedRoute path="/password/reset/confirm/:uid/:token" redirectTo="/" exact component={PasswordReset}/>,
  <Route path="/about" exact component={About} />,
  <AuthorizedRoute path="/my_account" exact component={MyAccount} />,
  <AuthorizedRoute path="/owned" exact component={MyAssets} />,
  <AuthorizedRoute path="/my_services/:service_id/consume_by/:provider_unique_id" exact component={MyServices} />,
  <AuthorizedRoute path="/my_services" exact component={MyServices} />,
  <AuthorizedRoute path="/my_provided_services" exact component={MyProvidedServices} />,
  <AuthorizedRoute path="/my_style_transfers" exact component={MyStyleTransferOrders} />,
    
  <AuthorizedRoute path="/liked" exact component={MyFavoriteAssets} />,
  <AuthorizedRoute path="/orders" exact component={MyOrders} />,
  <AuthorizedRoute path="/playlists" exact component={Playlists} />,
  <AuthorizedRoute path="/playlist_details/:collection_id" exact component={PlaylistDetails} />,
  <AuthorizedRoute path="/confirm_displayrequest/:confirmation_id" exact component={Playlists} />,
  <Route path="/explore" exact component={Explore} />,
  <AuthorizedRoute path="/my-items" exact component={MyAssets} />,
  <Route path="/explore/:asset_id" exact component={AssetDetails} />,
  <Route path="/explore/:asset_id/uid/:query_uid" exact component={AssetDetails} />,
  <AuthorizedRoute path="/remote_playlists" exact component={RemotePlayList} />,
  <AuthorizedRoute path="/slide_show/:collection_id" exact component={CollectionSlideShow} />,
  <Route path="/payment-result/received" exact component={PaymentResult} />,
  <Route path="/payment-result/canceled" exact component={PaymentCanceled} />,
  <Route path="/campaigns" exact component={Campaigns} />,
  <Route path="/campaigns/HASE202206" exact component={CampaignHASE202206} />,
  <Route path="/campaigns/:campaign_code" exact component={CampaignDetails} />,
  <Route path="/artist/:artist_uid" exact component={Artist} />,
  <Route path="/user/:user_uid" exact component={User} />,
  
  <Route path="/terms" exact component={Terms} />,
  <Route path="/sbtn_terms" exact component={SBTNTerms} />,
  <Route path="/sbtn_terms_v2" exact component={SBTNTermsV2} />,
  <Route path="/hase_terms" exact component={HASETerms} />,
  <Route path="/payme_cny_2023_terms" exact component={PaymeCNY2023Terms} />,

  <Route path="/privacy_policy" exact component={PrivacyPolicy} />,
  <Route path="/green_nft" exact component={GreenNFT} />,
  <Route path="/curated_creators" exact component={CuratedCreators} />,
  <Route path="/media_events" exact component={EventPage} />,
  <Route path="/ailab" exact component={AIlab} />,

  <Route path="/beasts-from-feasts" exact component={CampaignEcoMatcher202204} />,
  <Route path="/explore-fhs" exact component={CampaignFHS202201} />,
  <Route path="/explore-cary-parkinsons" exact component={CampaignHKPDA202203} />,
  <Route path="/explore-glhuet-for-roomtoread" exact component={CampaignROOMTOREAD202203} />,
  <Route path="/innoverse" exact component={Innoverse} />,
  <Route path="/innoverse/style_transfer" exact component={InnoverseStyleTransfer} />,
  <Route path="/innoverse/explore/:asset_id/claim/:claim_ctrl_trx_uid" exact component={InnoverseControlTrxDetails} />,
  <Route path="/innoverse/style_transfer/:package_id" exact component={InnoverseStyleTransferDetails} />,
  <Route path="/arles" exact component={Arles} />,
  <Route path="/arles/explore/:asset_id/claim/:claim_ctrl_trx_uid" exact component={ArlesControlTrxDetails} />,
  <Route path="/minisaur" exact component={CampaignMinisaur} />,

  <Route path="/service_packages" exact component={ServiceMarketPlace} />,
  <Route path="/service_packages_details/:package_id" exact component={ServicePackageDetails} />,
  
  <Route path="/scanner" exact component={Scanner} />,

]

export default routes
