const locales = [
  {
    locale: 'en',
    locale_text: 'en',
    messages: import('./en'),
    //loadData: import(`@formatjs/intl-relativetimeformat/dist/locale-data/en`),
  },
  {
    locale: 'zh-HK',
    locale_text: 'zh_HK',
    messages: import('./zh-HK'),
  },
  {
    locale: 'zh-CN',
    locale_text: 'zh_CN',
    messages: import('./zh-CN'),
  },
]

export default locales
